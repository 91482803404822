import { processNodeAndPermissions } from "../../../helper/com-helper";

const EDM_OBJECT = {
  name: "Enterprise Data Management",
  route: "/edm/product",
  permissions: [],
  child: {
    "Entities Data": {
      name: "Entities Data",
      route: null,
      permissions: [],
      child: {
        Product: {
          name: "Product",
          route: "/edm/product",
          permissions: ["PRODUCT_VIEW"],
        },
        Location: {
          name: "Location",
          route: "/edm/location",
          permissions: ["LOCATION_VIEW"],
        },
        Mapping: {
          name: "Mapping",
          route: "/edm/mapping",
          permissions: ["MAPPING_VIEW"],
        },
        Network: {
          name: "Network",
          route: "/edm/network",
          permissions: ["NETWORK_VIEW"],
        },
        Personnel: {
          name: "Personnel",
          route: "/edm/personnel",
          permissions: ["PERSONNEL_VIEW"],
        },
        Currency: {
          name: "Currency",
          route: "/edm/currency",
          permissions: ["CURRENCY_VIEW"],
        },
        Vehicle: {
          name: "Vehicle",
          route: "/edm/entity-management/master/vehicle-entity",
          permissions: ["VEHICLE_VIEW"],
        },
        "Raw Material": {
          name: "Raw Material",
          route: "/edm/raw-material",
          permissions: ["RAW_MATERIAL"],
        },
        "RM Node Mapping": {
          name: "RM Node Mapping",
          route: "/edm/rm-node-mapping",
          permissions: ["RM_NODE_MAPPING"],
        },
        Budget: {
          name: "Budget",
          route: "/edm/budget",
          permissions: ["BUDGET_VIEW"],
        },
        "Marketing Events": {
          name: "Marketing Events",
          route: "/edm/marketing-events",
          permissions: ["MARKETING_EVENTS_VIEW"],
        },
        "Promotion Type": {
          name: "Promotion Type",
          route: "/edm/promotion-type",
          permissions: ["PROMOTION_TYPES_VIEW"],
        },
        Split: {
          name: "Split",
          route: "/edm/splitmaster",
          permissions: ["SPLIT_VIEW"],
        },
        Promotions: {
          name: "Promotions",
          route: "/edm/promo-master",
          permissions: ["PROMO_MASTER_VIEW"],
        },
        Route: {
          name: "Route",
          route: "/edm/route-master",
          permissions: ["PROMO_MASTER_VIEW"],
        },
        "SNP Personnel": {
          name: "SNP Personnel",
          route: "/edm/snp-personnel",
          permissions: ["PROMO_MASTER_VIEW"],
        },
        "RM Network": {
          name: "RM Network",
          route: "/edm/rm-network",
          permissions: ["RM_NETWORK_VIEW"],
        },
      },
    },
    "Transaction Data": {
      name: "Transaction Data",
      route: null,
      permissions: [],
      child: {
        "Actual Sales History": {
          name: "Actual Sales History",
          permissions: ["SALESHISTORY_VIEW"],
          route: "/edm/actual-sales-history",
        },
        "Sales Order": {
          name: "Sales Order",
          permissions: ["SALES_ORDER__VIEW"],
          route: "/edm/sales-orders",
        },
        Feature: {
          name: "Feature",
          permissions: ["FEATURES_VIEW"],
          route: "/edm/feature",
        },
        Forecast: {
          name: "Forecast",
          permissions: ["FORECASTSUMMARY_VIEW"],
          route: "/edm/forecast-summary",
        },
        "Scope 1": {
          name: "Scope 1",
          permissions: ["SCOPE_1_VIEW"],
          route: "/edm/entity-management/transactional/scope-1",
        },
        "Scope 2": {
          name: "Scope 2",
          permissions: ["SCOPE_2_VIEW"],
          route: "/edm/entity-management/transactional/scope-2",
        },
        "Scope 3.1": {
          name: "Scope 3.1",
          permissions: ["SCOPE_3_1_VIEW"],
          route: "/edm/entity-management/transactional/scope-3-1",
        },
        "Scope 3.2": {
          name: "Scope 3.2",
          permissions: ["SCOPE_3_2_VIEW"],
          route: "/edm/entity-management/transactional/scope-3-2",
        },
        "Scope 3.3": {
          name: "Scope 3.3",
          permissions: ["SCOPE_3_3_VIEW"],
          route: "/edm/entity-management/transactional/scope-3-3",
        },
        "Scope 3.4": {
          name: "Scope 3.4",
          permissions: ["SCOPE_3_4_VIEW"],
          route: "/edm/entity-management/transactional/scope-3-4",
        },
        "Scope 3.5": {
          name: "Scope 3.5",
          permissions: ["SCOPE_3_5_VIEW"],
          route: "/edm/entity-management/transactional/scope-3-5",
        },
        "Scope 3.6": {
          name: "Scope 3.6",
          permissions: ["SCOPE_3_6_VIEW"],
          route: "/edm/entity-management/transactional/scope-3-6",
        },
        "Scope 3.7": {
          name: "Scope 3.7",
          permissions: ["SCOPE_3_7_VIEW"],
          route: "/edm/entity-management/transactional/scope-3-7",
        },
        "Scope 3.8": {
          name: "Scope 3.8",
          permissions: ["SCOPE_3_8_VIEW"],
          route: "/edm/entity-management/transactional/scope-3-8",
        },
        "Scope 3.9": {
          name: "Scope 3.9",
          permissions: ["SCOPE_3_9_VIEW"],
          route: "/edm/entity-management/transactional/scope-3-9",
        },
        "Scope 3.10": {
          name: "Scope 3.10",
          permissions: ["SCOPE_3_10_VIEW"],
          route: "/edm/entity-management/transactional/scope-3-10",
        },
        "Scope 3.11": {
          name: "Scope 3.11",
          permissions: ["SCOPE_3_11_VIEW"],
          route: "/edm/entity-management/transactional/scope-3-11",
        },
        "Scope 3.12": {
          name: "Scope 3.12",
          permissions: ["SCOPE_3_12_VIEW"],
          route: "/edm/entity-management/transactional/scope-3-12",
        },
        "Scope 3.13": {
          name: "Scope 3.13",
          permissions: ["SCOPE_3_13_VIEW"],
          route: "/edm/entity-management/transactional/scope-3-13",
        },
        "Scope 3.14": {
          name: "Scope 3.14",
          permissions: ["SCOPE_3_14_VIEW"],
          route: "/edm/entity-management/transactional/scope-3-14",
        },
        "Scope 3.15": {
          name: "Scope 3.15",
          permissions: ["SCOPE_3_15_VIEW"],
          route: "/edm/entity-management/transactional/scope-3-15",
        },
        "Goods In Transit": {
          name: "Goods In Transit",
          permissions: ["GOODS_IN_TRANSIT"],
          route: "/edm/goods-in-transit",
        },
        "Goods In Hand": {
          name: "Goods In Hand",
          permissions: ["GOODS_IN_HAND"],
          route: "/edm/goods-in-hand",
        },
        "Goods In PBNS": {
          name: "Goods In PBNS",
          permissions: ["GOODS_IN_PBNS"],
          route: "/edm/goods-in-pbns",
        },
        "Production Work Order": {
          name: "Production Work Order",
          permissions: ["PRODUCTION_WORK_ORDER"],
          route: "/edm/production-work-order",
        },
        "Bill Of Materials": {
          name: "Bill Of Materials",
          permissions: ["BILL_OF_MATERIALS"],
          route: "/edm/bill-of-materials",
        },
        "Stock Transfer Order": {
          name: "Stock Transfer Order",
          permissions: ["STOCK_TRANSFER_ORDER"],
          route: "/edm/stock-transfer-order",
        },
        "Purchase Order": {
          name: "Purchase Order",
          permissions: ["PURCHASE_ORDER"],
          route: "/edm/purchase-order",
        },
        "Raw Material Purchase Order": {
          name: "Raw Material Purchase Order",
          permissions: ["ROW_MATERIAL_PURCHASE_ORDER"],
          route: "/edm/rmpo",
        },
        Shipment: {
          name: "Shipment",
          permissions: ["SHIPMENT"],
          route: "/edm/shipment",
        },
        "Promotion Transactions": {
          name: "Promotion Transactions",
          permissions: ["PROMO_TRANSACTION_VIEW"],
          route: "/edm/promo-transaction",
        },
      },
    },
    "Transaction Log": {
      name: "Transaction Log",
      route: null,
      permissions: [],
      child: {
        "Transaction Log": {
          name: "Transaction Log",
          route: "/edm/transactionlog",
          permissions: ["TRANSACTION_LOG_VIEW"],
        },
      },
    },
  },
};

processNodeAndPermissions(EDM_OBJECT);
export const EDM = EDM_OBJECT;
